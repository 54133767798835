<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import Switches from "vue-switches";
import { email, minLength, required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Repository from "@/app/repository/repository-factory";
import { typeOfRoles } from "@/data/data-type-of-roles";
import { roleService } from "@/app/service/roleService";

const UserAccountRepository = Repository.get("UserAccountRepository");
const UserAdRepository = Repository.get("UserAdRepository");
const CoreCompanyRepository = Repository.get("CoreCompanyRepository");

/**
 * Add user account
 */
export default {
  page: {
    title: "Lista użytkowników",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, Preloader, Switches },
  data() {
    return {
      disabledBtn: false,
      preloader: false,
      inputLoading: false,
      userSelectOptionsField: [],
      userSelectValueField: null,
      userSearchValueField: null,
      userSearchedInRepository: false,
      requestErrorObj: null,
      header: {
        title: "Lista użytkowników",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista użytkowników",
            to: { name: "Lista użytkowników" },
          },
          {
            text: "Dodawanie użytkownika",
          },
        ],
      },
      form: {
        firstName: null,
        lastName: null,
        email: null,
        position: null,
        isActive: true,
        roles: [],
        typeOfUserAccount: { value: 2, text: "Konto Active Directory" },
        externalId: null,
        permissions: [],
      },
      roles: {
        acceptorPermission: [],
        acceptorCardPermission: [],
        acceptorKRSPermission: [],
        applicantPermission: [],
      },
      rolesSelectOptions: typeOfRoles,
      typeOfUserAccountSelectOptions: [
        { value: 1, text: "Konto lokalne" },
        { value: 2, text: "Konto Active Directory" },
      ],
      companySelectOptions: [],
      submitted: false,
    };
  },
  validations: {
    form: {
      firstName: { required, minLength: minLength(3) },
      lastName: { required, minLength: minLength(3) },
      email: { required, email },
      position: { minLength: minLength(3) },
      roles: { required },
      typeOfUserAccount: { required },
      externalId: {},
    },
  },
  created() {
    this.getCompanies();
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data.form, isValid);
      return isValid;
    },
    dispatchAction(el) {
      this.form.firstName = el.givenName;
      this.form.lastName = el.surname;
      this.form.email = el.mail;
      this.form.externalId = el.userPrincipalName;
    },
    userLabel({ givenName, surname, companyName, mail, userPrincipalName }) {
      return `${givenName} ${surname} ${companyName} ${mail} ${userPrincipalName}`;
    },
    async getCompanies() {
      this.preloader = true;
      await CoreCompanyRepository.getAllActive()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].companyId,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
      this.preloader = false;
    },
    addUserAccount() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let i;
      let roles = [];
      let applicantRole, acceptorRole, acceptorCardRole, acceptorKRSRole = false;
      for (i = 0; i < this.form.roles.length; i++) {
        if (this.form.roles[i].value === "ROLE_APPLICANT") {
          applicantRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING") {
          acceptorRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING_CARD") {
          acceptorCardRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING_KRS") {
          acceptorKRSRole = true;
        }
        roles.push(this.form.roles[i].value);
      }

      // Mapping permission to backend
      this.form.permission = [];
      if (applicantRole) {
        for (i = 0; i < this.roles.applicantPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 1,
            companyId: this.roles.applicantPermission[i].value,
          });
        }
      }

      if (acceptorRole) {
        for (i = 0; i < this.roles.acceptorPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 2,
            companyId: this.roles.acceptorPermission[i].value,
          });
        }
      }

      if (acceptorCardRole) {
        for (i = 0; i < this.roles.acceptorCardPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 4,
            companyId: this.roles.acceptorCardPermission[i].value,
          });
        }
      }

      if (acceptorKRSRole) {
        for (i = 0; i < this.roles.acceptorKRSPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 3,
            companyId: this.roles.acceptorKRSPermission[i].value,
          });
        }
      }

      this.disabledBtn = true;
      this.preloader = true;

      let payload = Object.assign({}, this.form);
      payload.roles = roles;
      payload.typeOfUserAccount = this.form.typeOfUserAccount.value;

      UserAccountRepository.create(payload)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Użytkownik został dodany!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "Lista użytkowników" });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },
    clearfields() {
      this.form.firstName = null;
      this.form.lastName = null;
      this.form.email = null;
      this.form.position = null;
      this.form.roles = [];
      this.roles.applicantPermission = [];
      this.roles.acceptorPermission = [];
      this.roles.acceptorCardPermission = [];
      this.form.externalId = null;
      this.form.permissions = [];
      this.userSearchValueField = null;
      this.userSelectValueField = null;
    },
    searchUser() {
      if (
        this.userSearchValueField !== null &&
        this.userSearchValueField !== ""
      ) {
        this.userSearchedInRepository = false;
        this.requestErrorObj = null;
        this.inputLoading = true;
        UserAdRepository.search(this.userSearchValueField)
          .then((response) => {
            this.userSelectOptionsField = response.data;
            this.userSearchedInRepository = true;
            this.inputLoading = false;
            this.clearfields();
          })
          .catch((error) => {
            this.userSearchedInRepository = false;
            this.requestErrorObj = error;
            this.inputLoading = false;
            console.log(error);
          });
      }
    },
    checkApplicantPermissionExist() {
      let r = [];
      this.roles.acceptorPermission.forEach((role) => {
        r = this.roles.applicantPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorCardPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorCardPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorKRSPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorKRSPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAndClearIfAccOrApp({ value }) {
      if (value == "ROLE_APPLICANT") {
        this.roles.applicantPermission = [];
      }
      if (value == "ROLE_ACCEPTING") {
        this.roles.acceptorPermission = [];
      }
      if (value == "ROLE_ACCEPTING_CARD") {
        this.roles.acceptorCardPermission = [];
      }
      if (value == "ROLE_ACCEPTING_KRS") {
        this.roles.acceptorKRSPermission = [];
      }
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />

    <b-form @submit.prevent="formSubmit" v-if="isAdmin">
      <div class="row">
        <div class="col-lg-7">
          <div class="card shadow-sm">
            <div class="card-body">
              <h4 class="card-title">Dodaj użytkownika</h4>
              <p class="card-title-desc">Tutaj możesz utworzyć nowego użytkownika.</p>
              <b-alert variant="info" class="mb-3" show fade>
                <i class="mdi mdi-information-outline mr-2"></i><strong>Uwaga!</strong> Możliwość logowania do panelu administratora posiadają tylko konta Active Directory.
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="firstName">Typ konta:</label>
                    <multiselect :class="{ 'is-invalid': submitted && $v.form.typeOfUserAccount.$error }" :multiple="false" :allow-empty="false" v-model="form.typeOfUserAccount" :options="typeOfUserAccountSelectOptions" :preserve-search="true" @input="clearfields" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>

                    <div v-if="submitted && $v.form.typeOfUserAccount.$error" class="invalid-feedback">
                      <span v-if="!$v.form.typeOfUserAccount.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row" v-if="form.typeOfUserAccount.value !== 1">
                <div class="form-group col-md-10 required">
                  <label for="userSearchValueField">Wyszukaj użytkownika</label>
                  <input id="userSearchValueField" v-model="userSearchValueField" class="form-control" type="text" name="userSearchValueField" placeholder="Wyszukaj nazwisko użytkownika AD" value required v-on:keyup.enter="searchUser" :disabled="inputLoading" re />
                  <small id="userSearchValueFieldHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Nazwisko użytkownika Active Directory. Wielkość liter nie ma znaczenia.</small>
                </div>
                <div class="form-group col-md-2 align-self-end text-right">
                  <button class="btn btn-info" @click="searchUser">
                    <i v-if="inputLoading" class="el-icon-loading mr-1"></i>
                    <i v-else class="el-icon-search mr-1"></i>
                    Szukaj
                  </button>
                  <small id="userSearchValueFieldHelp" class="form-text text-white">Search</small>
                </div>
              </div>
              <b-alert variant="info" class="mb-3" show fade v-if="userSearchedInRepository && userSelectOptionsField.length == 0 && form.typeOfUserAccount.value !== 1">
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie znaleziono użytkownika w bazie danych!
                <br /><small>Wprowadź poprawne dane lub wyszukaj innego użytkownika.</small>
              </b-alert>
              <b-alert variant="danger" class="mb-3" show fade v-if="requestErrorObj">
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Usługa niedostępna lub utracono połączenie z serwerem!
                <p class="m-0 p-0 mt-1 small">Wyloguj się z systemu i spróbuj ponownie. Jeżeli problem nadal występuje, skontaktuj się z pomocą techniczną.</p>
              </b-alert>
              <div class="form-group alert alert-success" v-if="userSearchedInRepository && userSelectOptionsField.length > 0 && form.typeOfUserAccount.value !== 1">
                <label for="userSelectValueField">Wybierz użytkownika</label>
                <multiselect id="userSelectValueField" :allow-empty="false" v-model.trim="userSelectValueField" :options="userSelectOptionsField" @select="dispatchAction" :custom-label="userLabel" track-by="upn" name="userSelectValueField" placeholder="Wybierz użytkownika z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.givenName }} {{ props.option.surname }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.givenName }} {{ props.option.surname }}</span>
                      <br />
                      <span class="option__title">Spółka: {{ props.option.companyName }}</span>
                      <br />
                      <template>
                        <span class="option__small small text-dark">Email: {{ props.option.mail }}</span>
                      </template>
                      <br />
                      <template>
                        <span class="option__small small text-dark">Zewnętrzny identyfikator konta: {{ props.option.userPrincipalName }}</span>
                        <br />
                      </template>
                    </div>
                  </template>
                  <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                </multiselect>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Imię:</label>
                    <input id="firstName" v-model="form.firstName" type="text" class="form-control" placeholder="Imię" value :class="{ 'is-invalid': submitted && $v.form.firstName.$error }" :disabled="form.typeOfUserAccount.value == 2" />
                    <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.firstName.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                      <span v-if="!$v.form.firstName.minLength">
                        Ta wartość musi być dłuższa niż 2 znaki.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Nazwisko:</label>
                    <input id="lastName" v-model="form.lastName" type="text" class="form-control" placeholder="Nazwisko" value :class="{ 'is-invalid': submitted && $v.form.lastName.$error }" :disabled="form.typeOfUserAccount.value == 2" />
                    <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.lastName.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                      <span v-if="!$v.form.lastName.minLength">
                        Ta wartość musi być dłuższa niż 2 znaki.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">E-mail:</label>
                    <input id="email" v-model="form.email" type="text" class="form-control" placeholder="Email" value :class="{ 'is-invalid': submitted && $v.form.email.$error }" :disabled="form.typeOfUserAccount.value == 2" />
                    <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.email.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                      <span v-if="!$v.form.email.email">
                        Ta wartość musi być poprawnym adresem e-mail.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="firstName">Stanowisko:</label>
                    <input id="position" v-model="form.position" type="text" class="form-control" placeholder="Stanowisko" value :class="{ 'is-invalid': submitted && $v.form.position.$error }" />
                    <div v-if="submitted && $v.form.position.$error" class="invalid-feedback">
                      <span v-if="!$v.form.position.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                      <span v-if="!$v.form.position.minLength">
                        Ta wartość musi być dłuższa niż 2 znaki.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div v-if="form.typeOfUserAccount.value !== 1" class="form-group required">
                    <label for="firstName">Zewnętrzny identyfikator konta:</label>
                    <input id="externalId" v-model="form.externalId" type="text" class="form-control" placeholder="Zewnętrzny identyfikator użytkownika" disabled />
                  </div>
                </div>

                <div class="col-lg-6"></div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-start">
                      <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                      <label class="mt-1 ml-3"><span class="mr-2">Aktywny:</span>
                        <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                        <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <button class="btn btn-dark" v-on:click="addUserAccount" :disabled="this.$v.$error || this.$v.$invalid || disabledBtn || checkApplicantPermissionExist().length > 0 || checkAcceptorPermissionExist().length > 0 || checkAcceptorCardPermissionExist().length > 0 || checkAcceptorKRSPermissionExist().length > 0" type="submit">Dodaj użytkownika</button>
            </div>
          </div>
          <div class="col-lg-4"></div>
        </div>

        <div class="col-lg-5">
          <div class="card shadow-sm">
            <div class="card-body">
              <h4 class="card-title">Zarządzanie uprawnieniami</h4>
              <p class="card-title-desc">Wybierz rolę i przypisz do niej uprawnienia.</p>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group required">
                    <label for="firstName">Rola:</label>
                    <multiselect @remove="checkAndClearIfAccOrApp" :class="{ 'is-invalid': submitted && $v.form.roles.$error }" :multiple="true" v-model="form.roles" :options="rolesSelectOptions" :optionsLimit="1000" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                      <template v-slot:noResult>Brak rezultatów.</template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.text }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.app }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>

                    <div v-if="submitted && $v.form.roles.$error" class="invalid-feedback">
                      <span v-if="!$v.form.roles.required">
                        Ta wartość jest wymagana.
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && form.roles.find(o => o.value === 'ROLE_APPLICANT')" class="form-group">
                    <label for="firstName">Uprawnienia wnioskującego:</label>
                    <multiselect :multiple="true" v-model="roles.applicantPermission" :options="companySelectOptions" :preserve-search="true" @input="checkApplicantPermissionExist" :class="{ 'is-invalid': checkApplicantPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkApplicantPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie wnioskującym i akceptującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && (form.roles.find(o => o.value === 'ROLE_ACCEPTING') || form.roles.find(o => o.value === 'ROLE_SUPER_ACCEPTING'))" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego przelewy:</label>
                    <multiselect :multiple="true" v-model="roles.acceptorPermission" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorPermissionExist" :class="{ 'is-invalid': checkAcceptorPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorPermissionExist().length > 0 || checkAcceptorCardPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && (form.roles.find(o => o.value === 'ROLE_ACCEPTING_CARD'))" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego zasilenie kart:</label>
                    <multiselect v-model="roles.acceptorCardPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorCardPermissionExist" :class="{ 'is-invalid': checkAcceptorCardPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorCardPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && form.roles.find(o => o.value === 'ROLE_ACCEPTING_KRS')" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego karty nierozliczone:</label>
                    <multiselect v-model="roles.acceptorKRSPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorKRSPermissionExist" :class="{ 'is-invalid': checkAcceptorKRSPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorKRSPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym KRS i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
